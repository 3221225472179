import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import DurationLabel from "app/pages/.shared/DurationLabel";
import Period from "app/pages/.shared/Period";
import { OFFER_TYPES } from "app/constants";
import first from "lodash/first";

const TravelDurationDates = ({ booking, inboundArrivalDayCount, outboundArrivalDayCount }) => {
	const endDateLabel =
		booking.offer && booking.offer.type === OFFER_TYPES.FLIGHT_WITH_ACCOMMODATION ? (
			<FormattedMessage id="my.booking.flight.end.date.label" />
		) : (
			<FormattedMessage id="my.booking.accommodation.end.date.label" />
		);

	const startDateLabel =
		booking.offer && booking.offer.type === OFFER_TYPES.FLIGHT_WITH_ACCOMMODATION ? (
			<FormattedMessage id="my.booking.flight.start.date.label" />
		) : (
			<FormattedMessage id="my.booking.accommodation.start.date.label" />
		);

	let inboundDepartureDate = booking.endDate;

	if (booking.offer && booking.offer.type === OFFER_TYPES.FLIGHT_WITH_ACCOMMODATION) {
		inboundDepartureDate =
			booking.flight &&
			booking.flight.inbound &&
			booking.flight.inbound.legs &&
			booking.flight.inbound.legs.length > 0
				? first(booking.flight.inbound.legs).from.takeOffDate
				: undefined;
	}

	return (
		<div className="travel-duration-dates">
			{booking.duration && booking.duration.value && (
				<div className="travel-duration-dates__item">
					<label className="travel-duration-dates__label">
						<FormattedMessage id="my.booking.duration.label" />
					</label>
					<div className="travel-duration-dates__value">
						<DurationLabel duration={booking.duration.value} />
					</div>
				</div>
			)}

			{booking.departureDate && (
				<div className="travel-duration-dates__item">
					<label className="travel-duration-dates__label">{startDateLabel}</label>
					<div className="travel-duration-dates__value">
						<Period
							fromDate={booking.departureDate}
							format={{
								weekday: "short",
								day: "2-digit",
								month: "short",
							}}
						/>

						{outboundArrivalDayCount > 0 && (
							<div className="travel-duration-dates__arrival-days">
								<FormattedMessage
									id="my.booking.flight.arrival.day.count.label"
									values={{
										days: outboundArrivalDayCount,
									}}
								/>
							</div>
						)}
					</div>
				</div>
			)}

			{inboundDepartureDate && (
				<div className="travel-duration-dates__item">
					<label className="travel-duration-dates__label">{endDateLabel}</label>
					<div className="travel-duration-dates__value">
						<Period
							fromDate={inboundDepartureDate}
							format={{
								weekday: "short",
								day: "2-digit",
								month: "short",
							}}
						/>

						{inboundArrivalDayCount > 0 && (
							<div className="travel-duration-dates__arrival-days">
								<FormattedMessage
									id="my.booking.flight.arrival.day.count.label"
									values={{
										days: inboundArrivalDayCount,
									}}
								/>
							</div>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

TravelDurationDates.propTypes = {
	booking: PropTypes.object,
	inboundArrivalDayCount: PropTypes.number,
	outboundArrivalDayCount: PropTypes.number,
};

TravelDurationDates.defaultProps = {
	booking: {},
};

export default React.memo(TravelDurationDates);
