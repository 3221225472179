import React from "react";
import PropTypes from "prop-types";
import { messagePropType } from "app/utils/propTypes";
import Paragraphs from "app/pages/.shared/Paragraphs";
import "./SpecialOffer.scss";
import PopoverInfo from "app/pages/.shared/Popover/PopoverInfo";

/**
 * Si background color non white, alors texte white. titleColor et textColor ignoré pour le block
 * Si background white, alors titleColor et textColor prise en compte pour le block
 * Pour le texte negotiated, on prend la couleur de titleColor
 * titleColor ne peut etre blanc
 */
const SpecialOffer = ({
	title,
	titleColor,
	pictoUrl,
	message,
	tooltipMessage,
	background,
	textColor,
}) => {
	const showTootipButton = tooltipMessage.length > 0;

	const hasBackgroundColor =
		background &&
		background !== "" &&
		background !== "white" &&
		background.toLowerCase() !== "#fff" &&
		background.toLowerCase() !== "#ffffff";

	const titleColorToApply = hasBackgroundColor ? "white" : titleColor;
	const textColorToApply = hasBackgroundColor ? "white" : textColor;

	return (
		<div className="special-offer" style={{ background: background }}>
			<p className="special-offer__title" style={{ color: titleColorToApply }}>
				{pictoUrl && (
					<i
						className="special-offer__picto"
						style={{
							maskImage: `url(${pictoUrl}`,
							WebkitMaskImage: `url(${pictoUrl}`,
						}}
					/>
				)}
				{title}
				{showTootipButton && (
					<PopoverInfo
						popOverButtonClassName="special-offer__tooltip"
						popOverContent={
							<Paragraphs enableHTMLFormatting={true} paragraphs={tooltipMessage} />
						}
					/>
				)}
			</p>
			<p className="special-offer__content" style={{ color: textColorToApply }}>
				{message.length > 0 && (
					<Paragraphs paragraphs={message} enableHTMLFormatting={true} />
				)}
			</p>
			{showTootipButton && (
				<div
					className="special-offer__content special-offer__details-print"
					style={{ color: textColorToApply }}
				>
					<Paragraphs enableHTMLFormatting={true} paragraphs={tooltipMessage} />
				</div>
			)}
		</div>
	);
};

SpecialOffer.propTypes = {
	titleColor: PropTypes.string,
	textColor: PropTypes.string,
	background: PropTypes.string,
	pictoUrl: PropTypes.string,
	title: messagePropType,
	message: PropTypes.array,
	tooltipMessage: PropTypes.array,
};

SpecialOffer.defaultProps = {
	message: [],
	tooltipMessage: [],
	background: "",
};

export default React.memo(SpecialOffer);
