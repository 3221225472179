import React from "react";
import PropTypes from "prop-types";
import IconPlane2 from "app/pages/.shared/IconPlane2";
import { TRANSPORTATION_TYPES } from "app/constants";
import IconNegoTrain from "app/pages/.shared/IconNegoTrain";
import IconNegoTransport from "app/pages/.shared/IconNegoTransport";

const TransportationPicto = ({ transportationTypes, ...props }) => {
	if (
		transportationTypes.length === 1 &&
		transportationTypes[0] === TRANSPORTATION_TYPES.FLIGHT
	) {
		return <IconPlane2 {...props} />;
	} else if (
		transportationTypes.length === 1 &&
		transportationTypes[0] === TRANSPORTATION_TYPES.TRAIN
	) {
		return <IconNegoTrain {...props} />;
	} else if (transportationTypes.length > 1) {
		return <IconNegoTransport {...props} />;
	}
	return false;
};

TransportationPicto.propTypes = {
	transportationTypes: PropTypes.array,
};

TransportationPicto.defaultProps = {
	transportationTypes: [],
};

export default React.memo(TransportationPicto);
