import React from "react";
import PropTypes from "prop-types";
import Badge from "app/pages/.shared/Badge";

const SpecialOfferBadge = ({ background, pictoUrl, title }) => {
	return (
		<Badge
			style={{
				display: "flex",
				alignItems: "center",
				color: "white",
				background: background,
			}}
		>
			<i
				style={{
					height: "13px",
					width: "13px",
					marginRight: "5px",
					backgroundColor: "currentColor",
					maskImage: `url(${pictoUrl}`,
					WebkitMaskImage: `url(${pictoUrl}`,
					MaskSize: "contain",
					WebkitMaskSize: "contain",
					MaskPosition: "center",
					WebkitMaskPosition: "center",
					MaskRepeat: "no-repeat",
					WebkitMaskRepeat: "no-repeat",
				}}
			/>
			<div>{title}</div>
		</Badge>
	);
};

SpecialOfferBadge.propTypes = {
	title: PropTypes.string,
	pictoUrl: PropTypes.string,
	background: PropTypes.string,
};

export default React.memo(SpecialOfferBadge);
