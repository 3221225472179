import { connect } from "react-redux";
import TravelDurationDates from "app/pages/.shared/TravelDurationDates/TravelDurationDates";
import {
	calculateInboundArrivalDayCount,
	calculateOutboundArrivalDayCount,
} from "app/pages/Booking/bookingSelectors";

const mapStateToProps = state => {
	return {
		booking: state.booking,
		inboundArrivalDayCount: calculateInboundArrivalDayCount(state),
		outboundArrivalDayCount: calculateOutboundArrivalDayCount(state),
	};
};

export default connect(mapStateToProps)(TravelDurationDates);
