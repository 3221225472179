import React from "react";
import { connect } from "react-redux";
import TransportationPicto from "app/pages/.shared/TransportationPicto/TransportationPicto";

const TransportationPictoContainer = props => {
	return <TransportationPicto {...props} />;
};

const mapStateToProps = state => {
	return {
		transportationTypes: state.ficheProduit.transportationTypes,
	};
};

export default connect(mapStateToProps)(TransportationPictoContainer);
