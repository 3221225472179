import { connect } from "react-redux";
import {
	calculateInboundArrivalDayCount,
	calculateOutboundArrivalDayCount,
} from "app/pages/Booking/bookingSelectors";
import TravelDates from "app/pages/.shared/TravelDates/TravelDates";

const mapStateToProps = state => {
	return {
		booking: state.booking,
		inboundArrivalDayCount: calculateInboundArrivalDayCount(state),
		outboundArrivalDayCount: calculateOutboundArrivalDayCount(state),
	};
};

export default connect(mapStateToProps)(TravelDates);
