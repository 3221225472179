import React from "react";
import PropTypes from "prop-types";
import { FormattedDate, FormattedMessage } from "react-intl";
import { OFFER_TYPES } from "app/constants";
import get from "lodash/get";
import first from "lodash/first";

const TravelDates = ({ booking, inboundArrivalDayCount, outboundArrivalDayCount }) => {
	const duration = get(booking, "duration.value");

	const departureDate = booking.departureDate && new Date(booking.departureDate);

	let inboundDepartureDate = booking.endDate;

	if (booking.offer && booking.offer.type === OFFER_TYPES.FLIGHT_WITH_ACCOMMODATION) {
		inboundDepartureDate =
			booking.flight &&
			booking.flight.inbound &&
			booking.flight.inbound.legs &&
			booking.flight.inbound.legs.length > 0
				? first(booking.flight.inbound.legs).from.takeOffDate
				: undefined;
	}

	return (
		<div className="travel-dates">
			<div className="travel-dates__dates" data-cy="detail-travel-dates">
				{departureDate && (
					<>
						<FormattedDate
							timeZone="UTC"
							value={departureDate}
							month="short"
							day="numeric"
							weekday="short"
						/>
						{outboundArrivalDayCount > 0 && (
							<>
								{" "}
								<FormattedMessage
									values={{ day: outboundArrivalDayCount }}
									id="general.arrival.extra.day.counter"
								/>
							</>
						)}
					</>
				)}
				{inboundDepartureDate && (
					<>
						{" - "}
						<FormattedDate
							timeZone="UTC"
							value={inboundDepartureDate}
							month="short"
							day="numeric"
							weekday="short"
						/>
						{inboundArrivalDayCount > 0 && (
							<>
								{" "}
								<FormattedMessage
									values={{ day: inboundArrivalDayCount }}
									id="general.arrival.extra.day.counter"
								/>
							</>
						)}
					</>
				)}
			</div>
			<div className="travel-dates__duration" data-cy="detail-travel-duration">
				<FormattedMessage
					id="sdp.search.summary.duration"
					values={{ nights: duration, days: duration + 1 }}
				/>
			</div>
		</div>
	);
};

TravelDates.propTypes = {
	booking: PropTypes.object,
	inboundArrivalDayCount: PropTypes.number,
	outboundArrivalDayCount: PropTypes.number,
};

TravelDates.defaultProps = {
	booking: {},
};

export default React.memo(TravelDates);
